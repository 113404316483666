<template>
  <div>
    <h2>营收汇总-商务收入、全案保量</h2>
    <div style='border: #e97ca1 1px dashed;padding: 10px'>
      <el-form :model='searchCondition' inline size='mini'>
        <el-form-item label='月份'>
          <el-date-picker
            v-model='searchCondition.ym'
            type='month'
            value-format='yyyy-MM'
            format='yyyy年MM月'
            placeholder='选择月份' style='width: 160px'
            @change='handleQuery'
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label='红人昵称'>
          <!--          <el-input v-model='searchCondition.artist_id' placeholder='星广汇红人ID' clearable></el-input>-->
          <el-select v-model='searchCondition.artist_id' filterable clearable>
            <el-option v-for='(option,index) in adKolOption' :key='index' :label='option.ad_nickname'
                       :value='option.ad_artist_id'></el-option>
          </el-select>
          <el-link @click='loadAdKolOptions' icon='el-icon-refresh'></el-link>
        </el-form-item>
        <!--        <el-form-item label='平台'>-->
        <!--          <el-input v-model='searchCondition.platform' placeholder='平台（星广汇）' clearable></el-input>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label='平台统一编号'>-->
        <!--          <el-input v-model='searchCondition.platform_value' placeholder='平台代号' clearable></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item>
          <el-button type='primary' @click='handleQuery' :disabled='loading' :loading='loading'>搜索</el-button>
          <el-button type='warning' @click='handleSync' :disabled='syncLoading' :loading='syncLoading'>执行同步</el-button>
        </el-form-item>
      </el-form>
      <div class='default-table'>
        <el-table :data='dataList'>
          <el-table-column type='index' label='序号'></el-table-column>
          <el-table-column label='红人昵称' prop='kol_nickname'></el-table-column>
          <el-table-column label='归属月份' prop='collect_at'></el-table-column>
          <el-table-column label='平台名称' prop='platform'></el-table-column>
          <el-table-column label='平台代号' prop='platform_value'></el-table-column>
          <el-table-column label='保量收入' prop='stage_amount'></el-table-column>
          <el-table-column label='商务营收' prop='biz_amount'></el-table-column>
          <el-table-column label='执行单数' prop='order_count'></el-table-column>
          <el-table-column label='星象仪红人ID' prop='pd_kol_id' show-overflow-tooltip></el-table-column>
          <el-table-column label='星广汇红人ID' prop='ad_kol_id' show-overflow-tooltip></el-table-column>
          <el-table-column label='首次统计时间' prop='created_at'></el-table-column>
          <el-table-column label='更新时间' prop='updated_at'></el-table-column>
        </el-table>
        <m-pagination :total.sync='total' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                      @pagination='getList'></m-pagination>
      </div>
    </div>
  </div>
</template>

<script>

const defaultTime = function() {
  let date = new Date()
  //分页页面-默认上月
  date.setMonth(date.getMonth() - 1)
  let year = date.getFullYear() + ''
  let month = date.getMonth() + 1
  return `${year}-${month}`
}

export default {
  name: 'AdIncome',
  data() {
    return {
      searchCondition: { ym: defaultTime(), artist_id: null, platform: null, platform_value: null },
      pageData: { page_size: 10, current_page: 1 },
      total: 0,
      dataList: [],
      loading: false,
      adKolOption: [],
      //同步相关
      syncLoading: false
    }
  },
  created() {
    this.loadAdKolOptions()
  },
  methods: {
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    async getList() {
      this.loading = true
      //
      let searchCondition = this.handleSearchCondition()
      searchCondition = Object.assign(searchCondition, this.pageData)
      let { list, page } = await this.$api.getRevenueOrderStatisticsV2(searchCondition)
      this.dataList = list
      this.total = page.total || 0
      this.pageData.page_size = page.page_size || 10
      this.pageData.current_page = page.current_page || 1
      setTimeout(() => {
        this.loading = false
      }, 2000)
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.ym) {
        // console.log('ym',this.searchCondition.ym)
        let ym = this.searchCondition.ym
        let ymArr = ym.split('-')
        condition.year = parseInt(ymArr[0])
        condition.month = parseInt(ymArr[1])
      }

      if (this.searchCondition.artist_id) {
        condition['artist_id'] = this.searchCondition.artist_id
      }
      if (this.searchCondition.platform_value) {
        condition['platform_value'] = this.searchCondition.platform_value
      }
      if (this.searchCondition.platform) {
        condition['platform'] = this.searchCondition.platform
      }
      return condition
    },
    async loadAdKolOptions() {
      let { list } = await this.$api.getAdKol()
      this.adKolOption = list
    },
    async handleSync() {
      this.syncLoading = true
      let searchCondition = this.handleSearchCondition()
      // console.log(searchCondition)
      let { msg } = await this.$api.syncRevenueOrderStatistics(searchCondition)
      this.msgSuccess(msg || '已执行')
      setTimeout(() => {
        this.syncLoading = false
      }, 2000)
    }
  }
}
</script>

<style scoped>

</style>
